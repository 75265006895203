import React from 'react'
import Layout from '../components/layout';
import styled from 'styled-components';

const ContactGroup = styled.div`
  border-radius: 5px;
  margin: 2rem auto;
  
  
  /*desktop version*/
  @media (min-width: 750px) {
    margin-top: 5rem;
    width:80ch;
    max-width: 85%;
    display: grid;
    place-items: center;
  }

  h3 {
    padding-top: .5rem;
    margin: 0 auto;
    color: ${({ theme }) => theme.text};
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.1;
    opacity: 0;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 5px;
    opacity: 0;
    animation: HeroAnimation 3s 0.3s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
    text-align: center;
  }

  @keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const Contact = () => {
  return (
    <Layout>
      <ContactGroup>
        <p>S.C. NUEBO DESIGN SRL</p>
        <p>CUI: 36932364</p>
        <p>J23/149/2017</p>
        <h3></h3>
        <p>Ion Aurel Coja - general manager & creative director</p>
        <p>+40773366058</p>
        <p>coja@nuebo.ro</p>
      </ContactGroup>
    </Layout>
  )
}

export default Contact;